import { Switch } from 'antd';
import React, { Component } from 'react';
interface ToggleThemeSwitchState {
  themeMode: string;
}

class ToggleThemeSwitch extends Component<{}, ToggleThemeSwitchState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      themeMode: localStorage.getItem('themeMode') || 'light',
    };
  }

  componentDidMount() {
    const savedThemeMode = localStorage.getItem('themeMode');
    if (savedThemeMode) {
      this.setState({ themeMode: savedThemeMode }, () => {
        this.applyThemeStyles(savedThemeMode);
      });
    } else {
      // Default theme mode if not found in localStorage
      this.applyThemeStyles(this.state.themeMode);
    }
  }

  setThemeMode = (mode: string) => {
    // const { forceRefreshAllCharts } = this.props;
    localStorage.setItem('themeMode', mode);
    this.setState({ themeMode: mode }, () => {
      this.applyThemeStyles(mode);
      // forceRefreshAllCharts();
    });
  };

  applyThemeStyles = (mode: string) => {
    // Define your CSS styles for light and dark themes
    const lightStyles = `
      .dashboard-component-header {
        background-image: linear-gradient(-90deg, #d1d1d1, #d1d1d1);
      }
      .css-1dgvt7y {
        background-color: #f1f1f1 !important;
      }
      .ant-tabs-nav-wrap {
        background-color: #f1f1f1 !important;
      }
      .ant-tabs-nav-list {
        background-color: #f1f1f1 !important;
      }
      .grid-content .dragdroppable-row .dragdroppable-column .dashboard-component-chart-holder {
        box-shadow: 0px 6px 6px #C7C8CC;
      }
    `;
    
    const darkStyles = `
      :root {
      filter: invert(0.85) hue-rotate(180deg) brightness(0.9) saturate(0.8);
    }
        `;

    const selectedStyles = mode === 'dark' ? darkStyles : lightStyles;
    let styleElement = document.getElementById('theme-styles');
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.setAttribute('id', 'theme-styles');
      document.head.appendChild(styleElement);
    }
    styleElement.innerHTML = selectedStyles;
  };

  render() {
    return (
      <Switch
        onChange={(value) => {
          const themeMode = value ? 'dark' : 'light';
          this.setThemeMode(themeMode);
        }}
        checkedChildren="🌙"
        unCheckedChildren="☀️"
        style={{ marginRight: 20, marginLeft: 20 }}
        checked={this.state.themeMode === 'dark'}
      />
    );
  }
}

export default ToggleThemeSwitch;